<template>
    <div class="comp-footer">
        <template v-if="theme === 'yingji'">
            <div class="footer">
                <div class="links">
                    <select placeholder="'友情链接'" class="select" v-model="selectedVal" @change="onSelect">
                        <option class="option" value="https://www.mem.gov.cn/"><span >国家应急管理部</span></option>
                        <option class="option" value="http://www.china-safety.org/"><span >国家应急管理宣教网</span></option>
                        <option class="option" value="http://www.ndrcc.org.cn/"><span >国家减灾网</span></option>
                        <option class="option" value="http://www.china-sem.org.cn/"><span >中国应急管理学会</span></option>
                        <option class="option" value="http://www.ccsr.cn/"><span >应急管理部研究中心</span></option>
                        <option class="option" value="http://www.chinajjjy.org.cn/"><span >应急管理部紧急救援促进中心</span></option>
                        <option class="option" value="http://www.nios.com.cn/"><span >应急管理部信息研究院</span></option>
                        <option class="option" value="http://www.wdecloud.com/"><span >伟东云教育集团</span></option>
                    </select>
                </div>
                <div class="info">
                    <div>主办单位：应急管理部宣传教育中心</div>
                    <div>承办单位：北京伟东国合教育科技有限公司</div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    data() {
        const theme = sessionStorage.getItem('theme');
        return {
            theme,
            selectedVal: 'https://www.mem.gov.cn/',
        }
    },
    methods: {
        onSelect(e) {
            // console.log(this.selectedVal, e.target);
            location.href = this.selectedVal;
        }
    }
}
</script>

<style lang="less">
.comp-footer {
    .footer {
        width: 100%;
        background: #333;
        height: 160px;
        display: flex;
    }

    .links {
        width: 260px;
        box-sizing: border-box;
        padding: 50px 20px;

        .select {
            width: 100%;
        }
    }
    .info {
        padding-top: 45px;
        flex: 1;
        font-size: 24px;
        color: #fff;
        // div{
        //     display: flex;
        // }
        // span{
        //     display: inline-block;
        // }
        // span:first-child{
        //     width: 143px;
        // }
        // span:last-child{
        //     flex: 1;
        // }
    }
}
</style>