var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"comp-footer"},[(_vm.theme === 'yingji')?[_c('div',{staticClass:"footer"},[_c('div',{staticClass:"links"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedVal),expression:"selectedVal"}],staticClass:"select",attrs:{"placeholder":"'友情链接'"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectedVal=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},_vm.onSelect]}},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7)])]),_vm._m(8)])]:_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('option',{staticClass:"option",attrs:{"value":"https://www.mem.gov.cn/"}},[_c('span',[_vm._v("国家应急管理部")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('option',{staticClass:"option",attrs:{"value":"http://www.china-safety.org/"}},[_c('span',[_vm._v("国家应急管理宣教网")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('option',{staticClass:"option",attrs:{"value":"http://www.ndrcc.org.cn/"}},[_c('span',[_vm._v("国家减灾网")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('option',{staticClass:"option",attrs:{"value":"http://www.china-sem.org.cn/"}},[_c('span',[_vm._v("中国应急管理学会")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('option',{staticClass:"option",attrs:{"value":"http://www.ccsr.cn/"}},[_c('span',[_vm._v("应急管理部研究中心")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('option',{staticClass:"option",attrs:{"value":"http://www.chinajjjy.org.cn/"}},[_c('span',[_vm._v("应急管理部紧急救援促进中心")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('option',{staticClass:"option",attrs:{"value":"http://www.nios.com.cn/"}},[_c('span',[_vm._v("应急管理部信息研究院")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('option',{staticClass:"option",attrs:{"value":"http://www.wdecloud.com/"}},[_c('span',[_vm._v("伟东云教育集团")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info"},[_c('div',[_vm._v("主办单位：应急管理部宣传教育中心")]),_c('div',[_vm._v("承办单位：北京伟东国合教育科技有限公司")])])
}]

export { render, staticRenderFns }