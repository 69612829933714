<template>
    <div class="guanhuFooter">
        <div class="left">
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-h5/shjiaoda/H5logo@2x.png" alt="">
        </div>
        <div class="right">
            <p class="text">主办单位：上海交大教育集团干部教育研究院 </p>
            <p class="text">承办单位：上海观乎教育科技有限公司</p>
        </div>
        <div class="fixed" @click="toKf">
             <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/hrss-h5/shjiaoda/kefu@2x.png"/>
             <div>在线咨询</div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            menus:[]
        }
    },
    created(){
    },
    methods: {
        toKf() {
            window.qimoChatClick();
        }
    }
}
</script>
<style lang="stylus" scoped>
.guanhuFooter{
    width 100%
    box-sizing border-box
    padding 34px 30px
    background #333333
    display flex
    justify-content space-between
    align-items center
    .left{
        img{
            width 180px
        }
    }
    .right{
        .text{
            font-size: 20px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 36px;
            margin-top :0;
        }
    }
    .fixed{
        position :fixed;
        bottom :150px;
        right :20px;
        background :#C50F23;
        width: 120px;
        height: 120px;
        border-radius :50%;
        text-align: center;
        img{
            width 50px;
            margin:14px 0 6px;
        }
        div{
            font-weight: 400;
            font-size: 20px;
            color: #FFFFFF;
            line-height: 24px;
        }
    }
}
</style>