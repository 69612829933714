<template>
    <div class="comp-header-guanhu">
        <template v-if="theme === 'shanghaijiaoda'">
            <div class="header">
                <div class="left"></div>
                <div class="center">
                    <span class="title">上海交大教育集团干部教育研究院</span>
                </div>
                <div class="right" @click="showOverlay = !showOverlay">
                </div>
            </div>
            <van-overlay :show="showOverlay" @click="showOverlay = false">
                <div class="menu-list" @click.stop>
                    <div class="menu" :class="{'active': item.url === $route.path}"
                        v-for="(item,index) in menus" :key="index" @click="jump(item.url)">
                        {{ item.name }}
                    </div>
                </div>
            </van-overlay>
        </template>
    </div>
</template>

<script>
import URL from './../../../config/url'
export default {
    props: {
        data: {
            type: Object,
        }
    },
    data() {
        const theme = sessionStorage.getItem('theme');
        // let menus = [];
        // console.log(this.data,'data---')
        // try {
        //     const { setting } = this.data;
        //     menus = setting.find(item => item.title === '菜单').content;
        // } catch (e) {
        //     console.log(e);
        // }
        // console.log(menus);

        return {
            showOverlay: false,
            theme,
            menus:[]
        }
    },
    created(){
        this.getMenu()
    },
    methods: {
        getMenu(){
            this.$api.home.getMenu(URL.confUrl).then((res) => {

                if (res.data) {         
                    this.menus = res.data
                }
            })
        },
        jump(url) {
            if (url.indexOf('http') === -1) {
                location.href = url;
                // this.$router.push({
                //     path: url,
                // });
            } else {
                window.open(url, 'newW');
            }
        }
    }
}
</script>

<style lang="less" scoped>
.comp-header-guanhu{
    height: 80px;

    .header {
        position: fixed;
        z-index: 100;
        width: 100%;
        height: 80px;
        background: #C50F23;
        display: flex;

        .left,
        .right {
            width: 94px;
        }
        .right {
            background: url('../asset/component/header/more.png') no-repeat center;
            background-size: 34px 34px;
        }
        .center {
            flex: 1;
            text-align: center;
            line-height: 80px;
        }
    }
    .menu-list {
        background: #fff;
        height: 100%;
        box-sizing: border-box;
        padding-top:120px;
        width: 480px;
        float: right;
        z-index: 9999;
        .menu {
            height: 100px;
            line-height: 100px;
            padding: 0 62px;
            font-size: 32px;

            a {
                color: #000;
            }

            &.active {
                background: #C50F23;
                color: #ffffff;
            }
        }
    }

    .title{
        font-size: 32px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
    }
}
</style>
