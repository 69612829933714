<template>
  <div class="slide" v-if="visibleType">
      <div v-for="(item,index) in data.setting[1].content" :key="index" class="slide-item">  
        <!-- <el-popover class="item" trigger="hover"  :content="item.value" placement="left" v-if="item.label.includes('电话')"> -->
        <!-- <el-popover
          placement="left"
          title="test"
          trigger="hover"
          width="200"
          :content="item.value"
          v-if="item.label.includes('电话')"> -->
      <!-- <el-popover
        placement="top-start"
        title="标题"
        width="200"
        trigger="hover"
        content="这是一段内容,这是一段内容,这是一段内容,这是一段内容。"
         v-if="item.label.includes('电话')">
            <div>
                <img src="./../asset/component/slide/phone.png"/>
                <p>{{item.label}}</p>
            </div>
        </el-popover> -->
        <el-popover
           v-if="item.label.includes('电话')"
          placement="left"
          trigger="hover"
          :content="item.value">
            <div  slot="reference">
                <img src="./../asset/component/slide/phone.png"/>
                <p>{{item.label}}</p>
            </div>
        </el-popover>
        <a :href="item.value" v-if="item.label.includes('客服')" target="_blank">
             <img src="./../asset/component/slide/talk.png"/>
            <p>{{item.label}}</p>
         </a>
        <div @click="goToTop">
        <img src="./../asset/component/slide/gotop.png" v-if="item.label.includes('返回顶部')"/>
         <p v-if="item.label.includes('返回顶部')">{{item.label}}</p>  
        </div> 
      </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    console.log("setttingData--------22222", this.data);
    return {
        visibleType:false
    };
  },
  mounted(){
     this.opinion()
  },
  methods:{
    opinion(){
			if((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
				if(this.data.setting[0].content[1].value) {
                  //移动端
                  this.visibleType = true
                  this.$nextTick(()=>{
                    let Slide = document.querySelector('.slide');
                    Slide.classList.add('move')
                  })
                }	
			}else {
				if(this.data.setting[0].content[0].value) {
                  this.visibleType = true
                }
			}    
		},
      goToTop(){
          document.documentElement.scrollTop = document.body.scrollTop = 0;
      }
  }

};
</script>

<style scoped>
.el-tooltip__popper.is-light {
    background: #FFF;
   border: 1px solid #D6DEEA!important; 
}
</style>

<style lang="less" scoped>
    /deep/ .is-light {
        background: #FFF;
        border: 1px solid #D6DEEA!important; 
    }
    .borderset{
        background: #FFF;
        background: #FFFFFF;
        box-shadow: 0px 0px 4px 0px rgb(0 0 0 / 15%);
        border: 1px solid #D6DEEA!important;       
    }
   .move{
        width:112px !important;
        right: 40px !important;
        box-shadow:none;
        bottom: 158px!important;
        .slide-item{
            height: 112px!important;
            p{
                font-size: 20px!important;
            }          
        }
    }
    .slide{
        position:fixed;
        right:24px;
        bottom: 352px;
        width:80px;
        background: #FFFFFF;
        box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
        border-radius: 0px 0px 4px 4px;
        border: 1px solid #D6DEEA;
        .slide-item{
            cursor: pointer;
            height:62px;
            width:100%;
            box-sizing: border-box;
            padding:2px 0;
            img{
                display: block;
                margin: 0 auto;
            }
            text-align: center;
            border-bottom: 1px solid #D6DEEA;
            &:nth-last-child(1){
                border-bottom:none;
            }
            p{
                font-size: 12px;
            }
        }
    }
</style>
