<template>
    <div class="comp-header">
        <template v-if="theme === 'yingji'">
            <div class="header">
                <div v-if="menus.length" class="left"></div>
                <div class="center">
                    <span class="logo"></span>
                </div>
                <div v-if="menus.length" class="right" @click="showOverlay = !showOverlay">
                </div>
            </div>
            <van-overlay :show="showOverlay" @click="showOverlay = false">
                <div class="menu-list" @click.stop>
                    <div class="menu" :class="{
                        'active': item.value === $route.path,
                    }"
                        v-for="item in menus" :key="item.id" @click="jump(item.value)">
                        {{ item.label }}
                        <!-- <router-link :to="{ path: item.value }">{{ item.label }} | {{ item.value }}</router-link> -->
                    </div>
                </div>
            </van-overlay>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
        }
    },
    data() {
        const theme = sessionStorage.getItem('theme');
        let menus = [];
        try {
            const { setting } = this.data;
            menus = setting.find(item => item.title === '菜单').content;
        } catch (e) {
            console.log(e);
        }
        console.log(menus);

        return {
            showOverlay: false,
            theme,
            menus,
        }
    },
    methods: {
        jump(url) {
            if (url.indexOf('http') === -1) {
                location.href = url;
                // this.$router.push({
                //     path: url,
                // });
            } else {
                window.open(url, 'newW');
            }
        }
    }
}
</script>

<style lang="less">
.comp-header {
    height: 88px;

    .header {
        position: fixed;
        z-index: 10000;
        width: 100%;
        height: 88px;
        background: #FF493E;
        display: flex;

        .left,
        .right {
            width: 94px;
        }
        .right {
            background: url('../asset/component/header/more.png') no-repeat center;
            background-size: 34px 34px;
        }
        .center {
            flex: 1;
            text-align: center;
        }
    }
    .van-overlay{
        z-index: 9999;
    }
    .menu-list {
        background: #fff;
        height: 100%;
        box-sizing: border-box;
        padding-top: 88px;
        width: 480px;
        float: right;
        z-index: 9999;
        .menu {
            height: 100px;
            line-height: 100px;
            padding: 0 62px;
            font-size: 32px;

            a {
                color: #383838;
            }

            &.active {
                background: #FFEDED;
            }
        }
    }


    .logo {
        margin-top: 25px;
        display: inline-block;
        width: 324px;
        height: 37px;
        background: url('../asset/component/header/logo.png');
        background-size: 324px 37px;
    }
}
</style>
